define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/info-window", "ember-google-maps/utils/options-and-events", "ember-google-maps/utils/helpers", "@ember/object", "rsvp"], function (_exports, _mapComponent, _infoWindow, _optionsAndEvents, _helpers, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.InfoWindowAPI = InfoWindowAPI;
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function InfoWindowAPI(source) {
    let mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      actions: {
        open: () => source.open(),
        close: () => source.close()
      }
    });
  }

  /**
   * A wrapper for the google.maps.InfoWindow class.
   *
   * @class InfoWindow
   * @namespace GMap
   * @module ember-google-maps/components/g-map/info-window
   * @extends GMap.MapComponent
   */
  var _default = _exports.default = _mapComponent.default.extend({
    layout: _infoWindow.default,
    _type: 'infoWindow',
    isOpen: false,
    _cachedIsOpen: false,
    position: (0, _object.computed)('lat', 'lng', _helpers.position),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'isOpen', 'target', 'content']),
    _createOptions(options) {
      let newOptions = {
        content: undefined
      };
      if (!(0, _object.get)(this, 'target')) {
        newOptions.position = (0, _object.get)(this, 'position');
      }
      if ((0, _object.get)(this, 'isOpen')) {
        newOptions.content = this._getContent();
      }
      return _objectSpread(_objectSpread({}, options), newOptions);
    },
    init() {
      this._super(...arguments);
      this.publicAPI = InfoWindowAPI(this);
    },
    _addComponent(options) {
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', new google.maps.InfoWindow(options)));
    },
    _didAddComponent() {
      this._openOrClose();
      this._super(...arguments);
    },
    _updateComponent(mapComponent, options) {
      mapComponent.setOptions(options);
      this._openOrClose();
    },
    _openOrClose() {
      let isOpen = (0, _object.get)(this, 'isOpen');
      let isOpenChanged = this._cachedIsOpen !== isOpen;
      if (isOpenChanged && isOpen) {
        this.open();
      } else if (isOpenChanged && !isOpen) {
        this.close();
      }
      (0, _object.set)(this, '_cachedIsOpen', isOpen);
    },
    _getContent() {
      if (this.content) {
        return this.content;
      }
      let content = document.createElement('div');
      (0, _object.set)(this, '_targetPane', content);
      (0, _object.set)(this, 'content', content);
      return content;
    },
    open() {
      if (this.mapComponent) {
        google.maps.event.addListenerOnce(this.mapComponent, 'closeclick', () => {
          (0, _object.set)(this, 'isOpen', false);
        });
        this.mapComponent.open((0, _object.get)(this, 'map'), (0, _object.get)(this, 'target'));
      }
    },
    close() {
      if (this.mapComponent) {
        this.mapComponent.close();
      }
    }
  });
});