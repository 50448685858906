define("ember-models-table/components/models-table/cell", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/cell", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _component2, _cell, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Table cell used within [models-table/table-row](Components.ModelsTableTableRow.html).
   *
   * Cell types:
   *
   * * Text. Shows some property value
   * * Route link with record id
   * * Route link with record property value
   * * Custom component
   *
   * See properties [routeName](Utils.ModelsTableColumn.html#property_routeName), [propertyName](Utils.ModelsTableColumn.html#property_propertyName), [component](Utils.ModelsTableColumn.html#property_component) for ModelsTableColumn.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} as |Row|>
   *           {{#each MT.visibleProcessedColumns as |column|}}
   *             <Row.Cell @column={{column}} @index={{index}} as |Cell|/>
   *               {{#if Cell.componentToRender}}
   *                 {{component Cell.componentToRender record=Cell.record column=column index=index}}
   *               {{/if}}
   *               {{! ... }}
   *             </Row.Cell>
   *           {{/each}}
   *         </Body.Row>
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableCell
   * @extends Ember.Component
   */
  let CellComponent = _exports.default = (_dec = (0, _component.layout)(_cell.default), _dec2 = (0, _component.tagName)('td'), _dec3 = (0, _computed.alias)('column.className'), _dec4 = (0, _object.computed)('column.editable', 'isEditRow'), _dec5 = (0, _object.computed)('column.{component,componentForEdit,propertyName}', 'isColumnEditable', 'isEditRow', 'themeInstance.{cellContentDisplayComponent,cellContentEditComponent}'), _dec(_class = _dec2(_class = (_class2 = class CellComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'td'
       */
      /**
       * @property columnClassName
       * @type string
       * @default ''
       * @protected
       */
      _initializerDefineProperty(this, "columnClassName", _descriptor, this);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @default null
       * @property record
       * @type object
       */
      _defineProperty(this, "record", null);
      /**
       * Row's index where current cell is
       *
       * @property index
       * @default null
       * @type number
       */
      _defineProperty(this, "index", null);
      /**
       * @property column
       * @default null
       * @type Utils.ModelsTableColumn
       */
      _defineProperty(this, "column", null);
      /**
       * @property isEditRow
       * @default null
       * @protected
       * @type boolean
       */
      _defineProperty(this, "isEditRow", null);
      /**
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTableRow.editRow](Components.ModelsTableRow.html#event_editRow)
       *
       * @event editRow
       */
      _defineProperty(this, "editRow", null);
      /**
       * Closure action [ModelsTableRow.saveRow](Components.ModelsTableRow.html#event_saveRow)
       *
       * @event saveRow
       */
      _defineProperty(this, "saveRow", null);
      /**
       * Closure action [ModelsTableRow.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *
       * @event cancelEditRow
       */
      _defineProperty(this, "cancelEditRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Is current row expanded or not
       *
       * @default null
       * @property isExpanded
       * @type boolean
       */
      _defineProperty(this, "isExpanded", null);
      /**
       * Is current row selected or not
       *
       * @default null
       * @property isSelected
       * @type boolean
       */
      _defineProperty(this, "isSelected", null);
    }
    /**
     * Is this column editable
     *
     * @protected
     * @property isColumnEditable
     * @type boolean
     * @default false
     */
    get isColumnEditable() {
      let isEditable = this.isEditRow;
      if (isEditable === true) {
        let columnEditable = this.column.editable;
        if (typeof columnEditable === 'function') {
          isEditable = columnEditable() || false;
        } else if (columnEditable === false) {
          isEditable = false;
        }
      }
      return isEditable;
    }

    /**
     * Given the mode for a cell (Edit or not) will determine which component to render
     *
     * @property componentToRender
     * @default null
     * @type ?string
     * @protected
     */
    get componentToRender() {
      if ((0, _utils.isNone)(this.column.propertyName)) {
        return undefined;
      }
      let editComponent = undefined;
      if (this.isColumnEditable) {
        editComponent = this.column.componentForEdit;
        editComponent = (0, _utils.isPresent)(editComponent) ? editComponent : this.themeInstance.cellContentEditComponent;
      }
      let cellDisplayComponent = this.column.component || this.themeInstance.cellContentDisplayComponent;
      return editComponent || cellDisplayComponent;
    }
    click(e) {
      if (this.isEditRow) {
        e.stopPropagation();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "columnClassName", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isColumnEditable", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isColumnEditable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "componentToRender", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "componentToRender"), _class2.prototype), _class2)) || _class) || _class);
});