define("ember-google-maps/components/g-map/circle", ["exports", "ember-google-maps/components/g-map/marker", "@ember/object", "@ember/object/computed", "rsvp"], function (_exports, _marker, _object, _computed, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Circle marker component.
   *
   * @class Circle
   * @namespace GMap
   * @module ember-google-maps/components/g-map/circle
   * @extends GMap.Marker
   */
  var _default = _exports.default = _marker.default.extend({
    _type: 'circle',
    radius: 500,
    center: (0, _computed.reads)('position'),
    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), {}, {
        map: this.map,
        radius: (0, _object.get)(this, 'radius'),
        center: (0, _object.get)(this, 'center')
      });
    },
    _addComponent(options) {
      return (0, _rsvp.resolve)((0, _object.set)(this, 'mapComponent', new google.maps.Circle(options)));
    }
  });
});