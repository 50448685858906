define("ember-models-table/components/models-table/table", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/array", "ember-models-table/templates/components/models-table/table"], function (_exports, _component, _component2, _object, _computed, _array, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Table with data used within [models-table](Components.ModelsTable.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table />
   *   {{! ... }}
   * </ModelsTable>
   * ```
   *
   * Usage example with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header />
   *     <Table.Body />
   *     <Table.Footer />
   *   </MT.Table>
   * </ModelsTable>
   * ```
   *
   * ModelsTableTable yields references to the following contextual components:
   *
   * * [models-table/table-header](Components.ModelsTableTableHeader.html) - table header. Component contains several rows like grouped headers, row with sorting buttons (usually with column titles) and row with filters for each column
   * * [models-table/table-body](Components.ModelsTableTableBody.html) - table body with data. Component contains current page with a subset or rows
   * * [models-table/table-footer](Components.ModelsTableTableFooter.html) - table footer. It's empty by default
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableTable
   * @extends Ember.Component
   */
  let TableComponent = _exports.default = (_dec = (0, _component.layout)(_table.default), _dec2 = (0, _component.tagName)('table'), _dec3 = (0, _computed.alias)('themeInstance.table'), _dec4 = (0, _computed.alias)('processedColumns.length'), _dec5 = (0, _object.computed)('visibleProcessedColumns.@each.componentForFooterCell'), _dec(_class = _dec2(_class = (_class2 = class TableComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'table'
       */
      /**
       * @property themeTableClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeTableClass", _descriptor, this);
      /**
       * Bound from [ModelsTable.noHeaderFilteringAndSorting](Components.ModelsTable.html#property_noHeaderFilteringAndSorting)
       *
       * @property noHeaderFilteringAndSorting
       * @type boolean
       * @default null
       */
      _defineProperty(this, "noHeaderFilteringAndSorting", null);
      /**
       * Bound from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders)
       *
       * @property groupedHeaders
       * @type GroupedHeader[][]
       * @default null
       */
      _defineProperty(this, "groupedHeaders", null);
      /**
       * Bound from [ModelsTable.processedColumns](Components.ModelsTable.html#property_processedColumns)
       *
       * @property processedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "processedColumns", null);
      /**
       * Closure action [ModelsTable.sort](Components.ModelsTable.html#event_sort)
       *
       * @event sort
       */
      _defineProperty(this, "sort", null);
      /**
       * @property columnsCount
       * @type number
       * @default 0
       * @protected
       */
      _initializerDefineProperty(this, "columnsCount", _descriptor2, this);
      /**
       * Bound from [ModelsTable.visibleContent](Components.ModelsTable.html#property_visibleContent)
       *
       * @property visibleContent
       * @type object[]
       * @default null
       */
      _defineProperty(this, "visibleContent", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @type number[]
       * @default null
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.allColumnsAreHidden](Components.ModelsTable.html#property_allColumnsAreHidden)
       *
       * @property allColumnsAreHidden
       * @type boolean
       * @default null
       */
      _defineProperty(this, "allColumnsAreHidden", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @type object[]
       * @default null
       */
      _defineProperty(this, "data", null);
      /**
       * Bound from [ModelsTable.useFilteringByColumns](Components.ModelsTable.html#property_useFilteringByColumns)
       *
       * @property useFilteringByColumns
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useFilteringByColumns", null);
      /**
       * Bound from [ModelsTable.groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent)
       *
       * @property groupingRowComponent
       * @type object
       * @default null
       */
      _defineProperty(this, "groupingRowComponent", null);
      /**
       * Bound from [ModelsTable.groupSummaryRowComponent](Components.ModelsTable.html#property_groupSummaryRowComponent)
       *
       * @property groupSummaryRowComponent
       * @type object
       * @default null
       */
      _defineProperty(this, "groupSummaryRowComponent", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable._collapsedGroupValues](Components.ModelsTable.html#property_collapsedGroupValues)
       *
       * @property collapsedGroupValues
       * @type array
       * @default null
       */
      _defineProperty(this, "collapsedGroupValues", null);
      /**
       * Bound from [ModelsTable.dataGroupOptions](Components.ModelsTable.html#property_dataGroupOptions)
       *
       * @property dataGroupOptions
       * @type object[]
       * @default null
       */
      _defineProperty(this, "dataGroupOptions", null);
      /**
       * Bound from [ModelsTable.groupedVisibleContentValuesOrder](Components.ModelsTable.html#property_groupedVisibleContentValuesOrder)
       *
       * @property groupedVisibleContentValuesOrder
       * @type array
       * @default null
       */
      _defineProperty(this, "groupedVisibleContentValuesOrder", null);
      /**
       * Bound from [ModelsTable.groupedVisibleContent](Components.ModelsTable.html#property_groupedVisibleContent)
       *
       * @property groupedVisibleContent
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedVisibleContent", null);
      /**
       * Bound from [ModelsTable.groupedArrangedContent](Components.ModelsTable.html#property_groupedArrangedContent)
       *
       * @property groupedArrangedContent
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedArrangedContent", null);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRows](Components.ModelsTable.html#event_toggleGroupedRows)
       *
       * @event toggleGroupedRows
       */
      _defineProperty(this, "toggleGroupedRows", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsSelection](Components.ModelsTable.html#event_toggleGroupedRowsSelection)
       *
       * @event toggleGroupedRowsSelection
       */
      _defineProperty(this, "toggleGroupedRowsSelection", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsExpands](Components.ModelsTable.html#event_toggleGroupedRowsExpands)
       *
       * @event toggleGroupedRowsExpands
       */
      _defineProperty(this, "toggleGroupedRowsExpands", null);
      /**
       * Closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *
       * @event clickOnRow
       */
      _defineProperty(this, "clickOnRow", null);
      /**
       * Closure action [ModelsTable.doubleClickOnRow](Components.ModelsTable.html#event_doubleClickOnRow)
       *
       * @event doubleClickOnRow
       */
      _defineProperty(this, "doubleClickOnRow", null);
      /**
       * Closure action [ModelsTable.hoverOnRow](Components.ModelsTable.html#event_hoverOnRow)
       *
       * @event hoverOnRow
       */
      _defineProperty(this, "hoverOnRow", null);
      /**
       * Closure action [ModelsTable.outRow](Components.ModelsTable.html#event_outRow)
       *
       * @event outRow
       */
      _defineProperty(this, "outRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.groupHeaderCellComponent](Components.ModelsTable.html#property_groupHeaderCellComponent)
       *
       * @property groupHeaderCellComponent
       * @type object
       * @default null
       */
      _defineProperty(this, "groupHeaderCellComponent", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
    }
    /**
     * @property showTableFooter
     * @type boolean
     * @default false
     * @protected
     */
    get showTableFooter() {
      return (0, _array.A)(this.visibleProcessedColumns).isAny('componentForFooterCell');
    }

    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     * @protected
     */
    doSort(column) {
      this.sort(column);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeTableClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "columnsCount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "showTableFooter", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "showTableFooter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doSort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype), _class2)) || _class) || _class);
});