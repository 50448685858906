define("ember-models-table/components/models-table/themes/ember-paper/select", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "ember-models-table/templates/components/models-table/themes/ember-paper/select"], function (_exports, _component, _object, _computed, _component2, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class EpModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let SelectComponent = _exports.default = (_dec = (0, _component.layout)(_select.default), _dec2 = (0, _computed.empty)('options'), _dec(_class = (_class2 = class SelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property disabled
       * @type boolean
       * @default true
       * @protected
       */
      _initializerDefineProperty(this, "disabled", _descriptor, this);
      /**
       * @property label
       * @type string
       * @default ''
       */
      _defineProperty(this, "label", '');
      /**
       * @property clearable
       * @type boolean
       * @default false
       */
      _defineProperty(this, "clearable", false);
      /**
       * @property wide
       * @type boolean
       * @default false
       */
      _defineProperty(this, "wide", false);
    }
    /**
     * @event updateValue
     * @param {*} val
     * @protected
     */
    updateValue(val) {
      let v = 'value' in val ? val.value : val;
      if (this.type === 'number') {
        v = +v;
      }
      (0, _object.set)(this, 'value', v);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "updateValue", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateValue"), _class2.prototype), _class2)) || _class);
});