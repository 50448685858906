define("ember-models-table/components/models-table/table-footer", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-models-table/templates/components/models-table/table-footer"], function (_exports, _component, _component2, _object, _tableFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * Table footer used within [models-table/table](Components.ModelsTableTable.html).
   *
   * By default it's an empty component. It should be used with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *       {{! ... }}
   *     </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example 2:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *        <tr>
   *          <td colspan={{if Footer.shouldAddExtraColumn  (inc MT.visibleProcessedColumns.length) MT.visibleProcessedColumns.length}}>
   *            {{! "inc" is a helper from `ember-composable-helpers` }}
   *            Some custom summary for table can be shown in the <code>tfoot</code>
   *          </td>
   *        </tr>
   *      </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [shouldAddExtraColumn](Components.ModelsTableTableFooter.html#property_shouldAddExtraColumn) - determines if extra column should be added to the row in the `tfoot`. It happens when rows grouping is used and extra column with group values exists
   *
   * @class ModelsTableTableFooter
   * @extends Ember.Component
   * @namespace Components
   */
  let TableFooterComponent = _exports.default = (_dec = (0, _component.layout)(_tableFooter.default), _dec2 = (0, _component.tagName)('tfoot'), _dec3 = (0, _object.computed)('displayGroupedValueAs', 'useDataGrouping', 'visibleProcessedColumns.[]'), _dec(_class = _dec2(_class = (_class2 = class TableFooterComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tfoot'
       */
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @type object
       * @default null
       */
      _defineProperty(this, "data", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Closure action [ModelsTable.goToPage](Components.ModelsTable.html#event_goToPage)
       *
       * @event goToPage
       */
      _defineProperty(this, "goToPage", null);
      /**
       * Closure action [ModelsTable.clearFilters](Components.ModelsTable.html#event_clearFilters)
       *
       * @event clearFilters
       */
      _defineProperty(this, "clearFilters", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
    }
    /**
     * @property shouldAddExtraColumn
     * @type boolean
     * @default false
     * @protected
     */
    get shouldAddExtraColumn() {
      return this.displayGroupedValueAs === 'column' && this.useDataGrouping && !!this.visibleProcessedColumns.length;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "shouldAddExtraColumn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAddExtraColumn"), _class2.prototype), _class2)) || _class) || _class);
});