define("ember-models-table/components/models-table/global-filter", ["exports", "@ember-decorators/component", "@ember/component", "ember-models-table/templates/components/models-table/global-filter", "@ember/object", "@ember/object/computed"], function (_exports, _component, _component2, _globalFilter, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Global filter element used within [models-table](Components.ModelsTable.html).
   *
   * Its value is used for all data-items and for each columns `propertyName`.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.GlobalFilter>
   *     {{input value=MT.globalFilter}}
   *     <button disabled={{if MT.globalFilterUsed "disabled"}} {{action (mut MT.globalFilter) ""}}>
   *       Clear Global Filter
   *     </button>
   *   </MT.GlobalFilter>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableGlobalFilter
   * @extends Ember.Component
   */
  let GlobalFilterComponent = _exports.default = (_dec = (0, _component.layout)(_globalFilter.default), _dec2 = (0, _computed.alias)('themeInstance.globalFilterWrapper'), _dec3 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class GlobalFilterComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property globalFilterWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "globalFilterWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.filterString](Components.ModelsTable.html#property_filterString)
       *
       * @property value
       * @type string
       * @default null
       */
      _defineProperty(this, "value", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.globalFilterUsed](Components.ModelsTable.html#property_globalFilterUsed)
       *
       * @property globalFilterUsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "globalFilterUsed", null);
    }
    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-global-filter`;
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}

    /**
     * @event updateGlobalFilterString
     * @protected
     * @param {Event} e
     */
    updateGlobalFilterString(e) {
      if (e) {
        e.stopPropagation();
      }
      (0, _object.set)(this, 'value', e.target.value);
      return false;
    }

    /**
     * @event clearGlobalFilter
     * @protected
     * @param {Event} e
     */
    clearGlobalFilter(e) {
      if (e) {
        e.stopPropagation();
      }
      (0, _object.set)(this, 'value', '');
      return false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "globalFilterWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateGlobalFilterString", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "updateGlobalFilterString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearGlobalFilter", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clearGlobalFilter"), _class2.prototype), _class2)) || _class);
});