define("ember-models-table/components/models-table/table-header", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/table-header"], function (_exports, _component, _component2, _object, _computed, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Table header used within [models-table/table](Components.ModelsTableTable.html).
   *
   * Component contains grouped headers (if provided) bound from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders), row with sorting buttons (usually this row also contains column headers) and row with filter boxes.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header />
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       {{#each groupedHeaders as |groupedHeader|}}
   *         <Header.GroupedHeader @groupedHeader={{groupedHeader}} as |GroupedHeader|>
   *           {{#each GroupedHeader.groupedHeader as |cell|}}
   *             <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
   *           {{/each}}
   *         </Header.GroupedHeader>
   *       {{/each}}
   *       <Header.RowSorting />
   *       <Header.RowFiltering />
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableTableHeader yields references to the following contextual components:
   *
   * * [models-table/grouped-header](Components.ModelsTableGroupedHeader.html) - component for groupedHeaders. It should be used for each groupedHeaders item
   * * [models-table/row-sorting](Components.ModelsTableRowSorting.html) - row with columns titles. Click on every cell will sort table data by selected column
   * * [models-table/row-filtering](Components.ModelsTableRowFiltering.html) - row with filter items. Every cell contains input or select-box
   * * [models-table/select-all-rows-checkbox](Components.ModelsTableSelectAllRowsCheckbox.html) - checkbox to select or deselect all rows
   * * [models-table/expand-all-toggle](Components.ModelsTableExpandAllToggle.html) - buttons to expand and collapse all rows
   *
   * Check own docs for each component to get detailed info.
   *
   * @namespace Components
   * @class ModelsTableTableHeader
   * @extends Ember.Component
   */
  let TableHeaderComponent = _exports.default = (_dec = (0, _component.layout)(_tableHeader.default), _dec2 = (0, _component.tagName)('thead'), _dec3 = (0, _computed.alias)('themeInstance.thead'), _dec4 = (0, _component.className)('table-header-no-filtering-and-sorting'), _dec(_class = _dec2(_class = (_class2 = class TableHeaderComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'thead'
       */
      /**
       * @property themeTheadClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeTheadClass", _descriptor, this);
      /**
       * Bound from [ModelsTable.noHeaderFilteringAndSorting](Components.ModelsTable.html#property_noHeaderFilteringAndSorting)
       *
       * @property noHeaderFilteringAndSorting
       * @type string
       * @default null
       * @protected
       */
      _initializerDefineProperty(this, "noHeaderFilteringAndSorting", _descriptor2, this);
      /**
       * Bound from [ModelsTable.groupedHeaders](Components.ModelsTable.html#property_groupedHeaders)
       *
       * @property groupedHeaders
       * @type GroupedHeader[]
       * @default null
       */
      _defineProperty(this, "groupedHeaders", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.processedColumns](Components.ModelsTable.html#property_processedColumns)
       *
       * @property processedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "processedColumns", null);
      /**
       * Bound from [ModelsTable.useFilteringByColumns](Components.ModelsTable.html#property_useFilteringByColumns)
       *
       * @property useFilteringByColumns
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useFilteringByColumns", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Bound from [ModelsTable.useDataGrouping](Components.ModelsTable.html#property_useDataGrouping)
       *
       * @property useDataGrouping
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useDataGrouping", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable.groupHeaderCellComponent](Components.ModelsTable.html#property_groupHeaderCellComponent)
       *
       * @property groupHeaderCellComponent
       * @type object
       * @default null
       */
      _defineProperty(this, "groupHeaderCellComponent", null);
      /**
       * Closure action [ModelsTable.sort](Components.ModelsTable.html#event_sort)
       *
       * @event sort
       */
      _defineProperty(this, "sort", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @default null
       * @type object[]
       */
      _defineProperty(this, "data", null);
    }
    /**
     * @event doSort
     * @param {Utils.ModelsTableColumn} column
     * @protected
     */
    doSort(column) {
      this.sort(column);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeTheadClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "noHeaderFilteringAndSorting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "doSort", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "doSort"), _class2.prototype), _class2)) || _class) || _class);
});