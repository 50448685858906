define("ember-google-maps/utils/options-and-events", ["exports", "@ember/object", "@ember/string", "@ember/runloop"], function (_exports, _object, _string, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.ignoredOptions = void 0;
  _exports.isEvent = isEvent;
  _exports.isIgnored = isIgnored;
  _exports.parseOptionsAndEvents = parseOptionsAndEvents;
  _exports.watch = watch;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const ignoredOptions = _exports.ignoredOptions = ['map', 'lat', 'lng', '_internalAPI', 'gMap', 'options', 'events', '_name'];
  function parseOptionsAndEvents(ignored = [], callback = r => r) {
    let ignoredSet = new Set(ignored);
    return (0, _object.computed)('attrs', function () {
      return callback(parseAttrs(ignoredSet, this.attrs));
    });
  }
  function parseAttrs(ignored = new Set(), args = {}) {
    let events = _objectSpread({}, args.events);
    let options = _objectSpread({}, args.options);
    let entries = Object.entries(args);
    entries.forEach(([k, v]) => {
      if (isIgnored(k, ignored)) {
        // Pass
      } else if (isEvent(k)) {
        events[k] = v;
      } else {
        options[k] = extractValue(v);
      }
    });
    return {
      events,
      options
    };
  }
  function isEvent(key) {
    return key.slice(0, 2) === 'on';
  }
  function isIgnored(key, ignored) {
    return ignored.has(key);
  }
  function extractValue(cell) {
    if (cell && cell.constructor && Object.keys(cell).includes('value')) {
      return cell.value;
    }
    return cell;
  }
  function watch(target, options = {}) {
    return Object.entries(options).map(([key, callback]) => addObserver(target, key, callback));
  }
  function addObserver(obj, key, callback) {
    let listener = obj.addObserver(key, callback);
    return {
      name: key,
      listener,
      remove: () => obj.removeObserver(key, callback)
    };
  }

  /* Events */

  function addEventListener(target, originalEventName, action, payload = {}) {
    let eventName = (0, _string.decamelize)(originalEventName).slice(3);
    function callback(googleEvent) {
      let params = _objectSpread({
        event: window.event,
        googleEvent,
        eventName,
        target
      }, payload);
      (0, _runloop.join)(target, action, params);
    }
    let listener = google.maps.event.addDomListener(target, eventName, callback);
    return {
      name: eventName,
      listener,
      remove: () => listener.remove()
    };
  }

  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function addEventListeners(target, events, payload = {}) {
    return Object.entries(events).map(([originalEventName, action]) => {
      return addEventListener(target, originalEventName, action, payload);
    });
  }
});