define("ember-models-table/components/models-table/select", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/select"], function (_exports, _component, _component2, _object, _computed, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   *
   * @class ModelsTableSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let SelectComponent = _exports.default = (_dec = (0, _component.layout)(_select.default), _dec2 = (0, _component.tagName)('select'), _dec3 = (0, _computed.empty)('options'), _dec4 = (0, _computed.alias)('themeInstance.input'), _dec5 = (0, _computed.alias)('themeInstance.select'), _dec(_class = _dec2(_class = (_class2 = class SelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'select'
       */
      /**
       * @property disabled
       * @type boolean
       * @protected
       */
      _initializerDefineProperty(this, "disabled", _descriptor, this);
      /**
       * @property themeInputClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeInputClass", _descriptor2, this);
      /**
       * @property themeSelectClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeSelectClass", _descriptor3, this);
      /**
       * @property type
       * @type string
       * @default ''
       */
      _defineProperty(this, "type", '');
      /**
       * @property cssPropertyName
       * @type string
       * @default ''
       */
      _initializerDefineProperty(this, "cssPropertyName", _descriptor4, this);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    change(e) {
      if (e) {
        e.stopPropagation();
      }
      let val = this.element.querySelector('option:checked').value;
      if (this.type === 'number') {
        val = +val;
      }
      (0, _object.set)(this, 'value', val);
      return false;
    }
    click(e) {
      if (e) {
        e.stopPropagation();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_component.attribute, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "themeInputClass", [_component.className, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "themeSelectClass", [_component.className, _dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "cssPropertyName", [_component.className], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _class2)) || _class) || _class);
});