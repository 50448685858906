define("ember-models-table/themes/default", ["exports", "@ember/object", "@ember/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.componentPath = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const componentPath = componentName => (0, _object.computed)('componentsPath', 'defaultComponentsPath', function () {
    const owner = (0, _application.getOwner)(this);
    const defaultPath = `${this.defaultComponentsPath}${componentName}`;
    const customPath = `${this.componentsPath}${componentName}`;
    return owner.lookup(`component:${customPath}`) ? customPath : defaultPath;
  });

  /**
   * Almost empty skeleton for themes. Extend it to provide custom CSS-classes for table items and icons.
   *
   * * Every property with suffix `Component` is a path to the component used in theme
   * * Every property with suffix `Msg` is a message shown in the table
   * * Every property with suffix `Icon` is a CSS-class for font-library used as an icons (used for buttons, carets etc)
   *
   * **Difference from `v.2`:**
   *
   * * No `mergedProperties`
   * * Properties `components`, `messages` and `icons` are removed. Every their key is placed directly in theme and named with suffix `Component`, `Msg` or `Icon`.
   * * Every property name is converted to the `lowerCamelCase`
   *
   * @class DefaultTheme
   * @namespace Themes
   */
  _exports.componentPath = componentPath;
  let DefaultTheme = _exports.default = (_dec = componentPath('cell'), _dec2 = componentPath('cell-content-display'), _dec3 = componentPath('cell-content-edit'), _dec4 = componentPath('cell-edit-toggle'), _dec5 = componentPath('cell-column-summary'), _dec6 = componentPath('columns-dropdown'), _dec7 = componentPath('columns-hidden'), _dec8 = componentPath('data-group-by-select'), _dec9 = componentPath('expand-all-toggle'), _dec10 = componentPath('expand-toggle'), _dec11 = componentPath('footer'), _dec12 = componentPath('global-filter'), _dec13 = componentPath('grouped-header'), _dec14 = componentPath('no-data'), _dec15 = componentPath('page-size-select'), _dec16 = componentPath('pagination-numeric'), _dec17 = componentPath('pagination-simple'), _dec18 = componentPath('row'), _dec19 = componentPath('row-expand'), _dec20 = componentPath('row-filtering'), _dec21 = componentPath('row-filtering-cell'), _dec22 = componentPath('row-grouping'), _dec23 = componentPath('row-group-toggle'), _dec24 = componentPath('row-select-all-checkbox'), _dec25 = componentPath('row-select-checkbox'), _dec26 = componentPath('row-sorting'), _dec27 = componentPath('row-sorting-cell'), _dec28 = componentPath('select'), _dec29 = componentPath('summary'), _dec30 = componentPath('table'), _dec31 = componentPath('table-body'), _dec32 = componentPath('table-footer'), _dec33 = componentPath('table-header'), _class = class DefaultTheme extends _object.default {
    constructor(...args) {
      super(...args);
      /**
       * Path to theme's components. It's used in the child-themes
       *
       * @property componentsPath
       * @default 'models-table/'
       * @type string
       */
      _defineProperty(this, "componentsPath", 'models-table/');
      /**
       * Default path to theme's components
       *
       * @property defaultComponentsPath
       * @default 'models-table/'
       * @type string
       */
      _defineProperty(this, "defaultComponentsPath", 'models-table/');
      /**
       * @property cellComponent
       * @type string
       * @default 'models-table/cell'
       */
      _initializerDefineProperty(this, "cellComponent", _descriptor, this);
      /**
       * @property cellContentDisplayComponent
       * @type string
       * @default 'models-table/cell-content-display'
       */
      _initializerDefineProperty(this, "cellContentDisplayComponent", _descriptor2, this);
      /**
       * @property cellContentEditComponent
       * @type string
       * @default 'models-table/cell-content-edit'
       */
      _initializerDefineProperty(this, "cellContentEditComponent", _descriptor3, this);
      /**
       * @property cellEditToggleComponent
       * @type string
       * @default 'models-table/cell-edit-toggle'
       */
      _initializerDefineProperty(this, "cellEditToggleComponent", _descriptor4, this);
      /**
       * @property cellContentSummaryComponent
       * @type string
       * @default 'models-table/cell-column-summary'
       */
      _initializerDefineProperty(this, "cellContentSummaryComponent", _descriptor5, this);
      /**
       * @property columnsDropdownComponent
       * @type string
       * @default 'models-table/columns-dropdown'
       */
      _initializerDefineProperty(this, "columnsDropdownComponent", _descriptor6, this);
      /**
       * @property columnsHiddenComponent
       * @type string
       * @default 'models-table/columns-hidden'
       */
      _initializerDefineProperty(this, "columnsHiddenComponent", _descriptor7, this);
      /**
       * @property dataGroupBySelectComponent
       * @type string
       * @default 'models-table/data-group-by-select'
       */
      _initializerDefineProperty(this, "dataGroupBySelectComponent", _descriptor8, this);
      /**
       * @property expandAllToggleComponent
       * @type string
       * @default 'models-table/expand-all-toggle'
       */
      _initializerDefineProperty(this, "expandAllToggleComponent", _descriptor9, this);
      /**
       * @property expandToggleComponent
       * @type string
       * @default 'models-table/expand-toggle'
       */
      _initializerDefineProperty(this, "expandToggleComponent", _descriptor10, this);
      /**
       * @property footerComponent
       * @type string
       * @default 'models-table/footer'
       */
      _initializerDefineProperty(this, "footerComponent", _descriptor11, this);
      /**
       * @property globalFilterComponent
       * @type string
       * @default 'models-table/global-filter'
       */
      _initializerDefineProperty(this, "globalFilterComponent", _descriptor12, this);
      /**
       * @property groupedHeaderComponent
       * @type string
       * @default 'models-table/grouped-header'
       */
      _initializerDefineProperty(this, "groupedHeaderComponent", _descriptor13, this);
      /**
       * @property noDataComponent
       * @type string
       * @default 'models-table/no-data'
       */
      _initializerDefineProperty(this, "noDataComponent", _descriptor14, this);
      /**
       * @property pageSizeSelectComponent
       * @type string
       * @default 'models-table/page-size-select'
       */
      _initializerDefineProperty(this, "pageSizeSelectComponent", _descriptor15, this);
      /**
       * @property paginationNumericComponent
       * @type string
       * @default 'models-table/pagination-numeric'
       */
      _initializerDefineProperty(this, "paginationNumericComponent", _descriptor16, this);
      /**
       * @property paginationSimpleComponent
       * @type string
       * @default 'models-table/pagination-simple'
       */
      _initializerDefineProperty(this, "paginationSimpleComponent", _descriptor17, this);
      /**
       * @property rowComponent
       * @type string
       * @default 'models-table/row'
       */
      _initializerDefineProperty(this, "rowComponent", _descriptor18, this);
      /**
       * @property rowExpandComponent
       * @type string
       * @default 'models-table/row-expand'
       */
      _initializerDefineProperty(this, "rowExpandComponent", _descriptor19, this);
      /**
       * @property rowFilteringComponent
       * @type string
       * @default 'models-table/row-filtering'
       */
      _initializerDefineProperty(this, "rowFilteringComponent", _descriptor20, this);
      /**
       * @property rowFilteringCellComponent
       * @type string
       * @default 'models-table/row-filtering-cell'
       */
      _initializerDefineProperty(this, "rowFilteringCellComponent", _descriptor21, this);
      /**
       * @property rowGroupingComponent
       * @type string
       * @default 'models-table/row-grouping'
       */
      _initializerDefineProperty(this, "rowGroupingComponent", _descriptor22, this);
      /**
       * @property rowGroupToggleComponent
       * @type string
       * @default 'models-table/row-group-toggle'
       */
      _initializerDefineProperty(this, "rowGroupToggleComponent", _descriptor23, this);
      /**
       * @property rowSelectAllCheckboxComponent
       * @type string
       * @default 'models-table/row-select-all-checkbox'
       */
      _initializerDefineProperty(this, "rowSelectAllCheckboxComponent", _descriptor24, this);
      /**
       * @property rowSelectCheckboxComponent
       * @type string
       * @default 'models-table/row-select-checkbox'
       */
      _initializerDefineProperty(this, "rowSelectCheckboxComponent", _descriptor25, this);
      /**
       * @property rowSortingComponent
       * @type string
       * @default 'models-table/row-sorting'
       */
      _initializerDefineProperty(this, "rowSortingComponent", _descriptor26, this);
      /**
       * @property rowSortingCellComponent
       * @type string
       * @default 'models-table/row-sorting-cell'
       */
      _initializerDefineProperty(this, "rowSortingCellComponent", _descriptor27, this);
      /**
       * @property selectComponent
       * @type string
       * @default 'models-table/select'
       */
      _initializerDefineProperty(this, "selectComponent", _descriptor28, this);
      /**
       * @property summaryComponent
       * @type string
       * @default 'models-table/summary'
       */
      _initializerDefineProperty(this, "summaryComponent", _descriptor29, this);
      /**
       * @property tableComponent
       * @type string
       * @default 'models-table/table'
       */
      _initializerDefineProperty(this, "tableComponent", _descriptor30, this);
      /**
       * @property tableBodyComponent
       * @type string
       * @default 'models-table/table-body'
       */
      _initializerDefineProperty(this, "tableBodyComponent", _descriptor31, this);
      /**
       * @property tableFooterComponent
       * @type string
       * @default 'models-table/table-footer'
       */
      _initializerDefineProperty(this, "tableFooterComponent", _descriptor32, this);
      /**
       * @property tableHeaderComponent
       * @type string
       * @default 'models-table/table-header'
       */
      _initializerDefineProperty(this, "tableHeaderComponent", _descriptor33, this);
      /**
       * @property cellContentTagName
       * @type string
       * @default ''
       */
      _defineProperty(this, "cellContentTagName", '');
      /**
       * @property searchLabelMsg
       * Label for global filter
       *
       * @type string
       * @default 'Search:'
       */
      _defineProperty(this, "searchLabelMsg", 'Search:');
      /**
       * Placeholder for global filter
       *
       * @property searchPlaceholderMsg
       * @type string
       * @default ''
       */
      _defineProperty(this, "searchPlaceholderMsg", '');
      /**
       * Label for dropdown with columns for rows grouping
       *
       * @property groupByLabelMsg
       * @type string
       * @default 'Group by:'
       */
      _defineProperty(this, "groupByLabelMsg", 'Group by:');
      /**
       * Text on toggle for columns dropdown
       *
       * @property columnsTitleMsg
       * @type string
       * @default 'Columns'
       */
      _defineProperty(this, "columnsTitleMsg", 'Columns');
      /**
       * Label for button to show all table columns (under columns dropdown)
       *
       * @property columnsShowAllMsg
       * @type string
       * @default 'Show All'
       */
      _defineProperty(this, "columnsShowAllMsg", 'Show All');
      /**
       * Label for button to hide all table columns (under columns dropdown)
       *
       * @property columnsHideAllMsg
       * @type string
       * @default 'Hide All'
       */
      _defineProperty(this, "columnsHideAllMsg", 'Hide All');
      /**
       * Label for button to restore default visibility for table columns (under columns dropdown)
       *
       * @property columnsRestoreDefaultsMsg
       * @type string
       * @default 'Restore Defaults'
       */
      _defineProperty(this, "columnsRestoreDefaultsMsg", 'Restore Defaults');
      /**
       * Message shown in the table summary. It's used with three options:
       *
       * 1. First row's index
       * 2. Last row's index
       * 3. Overall rows count
       *
       * @property tableSummaryMsg
       * @type string
       * @default 'Show %@ - %@ of %@'
       */
      _defineProperty(this, "tableSummaryMsg", 'Show %@ - %@ of %@');
      /**
       * Message shown when all columns are hidden. It's shown inside table body
       *
       * @property allColumnsAreHiddenMsg
       * @type string
       * @default 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them'
       */
      _defineProperty(this, "allColumnsAreHiddenMsg", 'All columns are hidden. Use <strong>columns</strong>-dropdown to show some of them');
      /**
       * Message shown when there are not data to display in the table. It's shown inside table body in cases when initial `data` is empty or when all records are filtered out
       *
       * @property noDataToShowMsg
       * @type string
       * @default 'No records to show'
       */
      _defineProperty(this, "noDataToShowMsg", 'No records to show');
      /**
       * Default label for button "Edit" inside the `cell-edit-toggle`-component
       *
       * @property editRowButtonLabelMsg
       * @type string
       * @default 'Edit'
       */
      _defineProperty(this, "editRowButtonLabelMsg", 'Edit');
      /**
       * Default label for button "Save" inside the `cell-edit-toggle`-component
       *
       * @property saveRowButtonLabelMsg
       * @type string
       * @default 'Save'
       */
      _defineProperty(this, "saveRowButtonLabelMsg", 'Save');
      /**
       * Default label for button "Cancel" inside the `cell-edit-toggle`-component
       *
       * @property cancelRowButtonLabelMsg
       * @type string
       * @default 'Cancel'
       */
      _defineProperty(this, "cancelRowButtonLabelMsg", 'Cancel');
      /**
       * Label for dropdown with page numbers. Used in both numeric and simple pagination
       *
       * @property currentPageNumberMsg
       * @type string
       * @default 'Page:'
       */
      _defineProperty(this, "currentPageNumberMsg", 'Page:');
      /**
       * Label for dropdown with rows count shown in the page
       *
       * @property rowsCountMsg
       * @type string
       * @default 'Rows:'
       */
      _defineProperty(this, "rowsCountMsg", 'Rows:');
      /**
       * Label for "First"-page in the numeric pagination. It's used for screen-readers and not "visible" by default
       *
       * @property goToFirstPageButtonTextMsg
       * @type string
       * @default 'Go to first page'
       */
      _defineProperty(this, "goToFirstPageButtonTextMsg", 'Go to first page');
      /**
       * Label for "Previous"-page in the numeric pagination. It's used for screen-readers and not "visible" by default
       *
       * @property goToPrevPageButtonTextMsg
       * @type string
       * @default 'Go to previous page'
       */
      _defineProperty(this, "goToPrevPageButtonTextMsg", 'Go to previous page');
      /**
       * Label for "Next"-page in the numeric pagination. It's used for screen-readers and not "visible" by default
       *
       * @property goToNextPageButtonTextMsg
       * @type string
       * @default 'Go to next page'
       */
      _defineProperty(this, "goToNextPageButtonTextMsg", 'Go to next page');
      /**
       * Label for "Last"-page in the numeric pagination. It's used for screen-readers and not "visible" by default
       *
       * @property goToLastPageButtonTextMsg
       * @type string
       * @default 'Go to last page'
       */
      _defineProperty(this, "goToLastPageButtonTextMsg", 'Go to last page');
      /**
       * Label for "Clear global filter"-button. It's used for screen-readers and not "visible" by default
       *
       * @property clearGlobalFilterMsg
       * @type string
       * @default 'Clear global filter input'
       */
      _defineProperty(this, "clearGlobalFilterMsg", 'Clear global filter input');
      /**
       * Label for "Clear filter"-buttons in the table header's cells. It's used for screen-readers and not "visible" by default
       *
       * @property clearFilterMsg
       * @type string
       * @default 'Clear filter input'
       */
      _defineProperty(this, "clearFilterMsg", 'Clear filter input');
      /**
       * Label for "Clear all filters"-button in the table summary section. It's used for screen-readers and not "visible" by default
       *
       * @property clearAllFiltersMsg
       * @type string
       * @default 'Clear all filters'
       */
      _defineProperty(this, "clearAllFiltersMsg", 'Clear all filters');
      /**
       * CSS-classes for `table`-tag
       *
       * @property table
       * @type string
       * @default ''
       */
      _defineProperty(this, "table", '');
      /**
       * @property buttonsGroup
       * @type string
       * @default ''
       */
      _defineProperty(this, "buttonsGroup", '');
      /**
       * CSS-classes for `div`-wrapper over components `global-filter`, `data-group-by-select` and `columns-dropdown`
       *
       * @property headerWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "headerWrapper", '');
      /**
       * CSS-classes for wrapper used inside `global-filter` component
       *
       * @property globalFilterWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "globalFilterWrapper", '');
      /**
       * CSS-classes for wrapper used inside `columns-dropdown` component
       *
       * @property columnsDropdownWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnsDropdownWrapper", '');
      /**
       * @property columnsDropdownButtonWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnsDropdownButtonWrapper", '');
      /**
       * CSS-classes for wrapper over list inside `columns-dropdown` component
       *
       * @property columnsDropdown
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnsDropdown", '');
      /**
       * CSS-classes for divider for list inside `columns-dropdown` components. Divider is placed before single-column items by default
       *
       * @property columnsDropdownDivider
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnsDropdownDivider", '');
      /**
       * CSS-classes for wrapper inside `data-group-by-select` component
       *
       * @property dataGroupBySelectWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "dataGroupBySelectWrapper", 'data-group-by-wrapper');
      /**
       * CSS-classes for thead cells
       *
       * @property theadCell
       * @type string
       * @default 'table-header'
       */
      _defineProperty(this, "theadCell", 'table-header');
      /**
       * CSS-classes used for thead-cells with columns titles. This class is used only if columns is not sortable
       *
       * @property theadCellNoSorting
       * @type string
       * @default 'table-header-no-sorting'
       */
      _defineProperty(this, "theadCellNoSorting", 'table-header-no-sorting');
      /**
       * CSS-classes used for thead-cells with columns filters. This class is used only if columns is not filterable
       *
       * @property theadCellNoFiltering
       * @type string
       * @default 'table-header-no-filtering'
       */
      _defineProperty(this, "theadCellNoFiltering", 'table-header-no-filtering');
      /**
       * CSS-classes for selected rows. Used in the `row` component
       *
       * @property selectedRow
       * @type string
       * @default 'selected-row'
       */
      _defineProperty(this, "selectedRow", 'selected-row');
      /**
       * CSS-classes for expanded rows. Used in the `row` component
       *
       * @property expandedRow
       * @type string
       * @default 'expanded-row'
       */
      _defineProperty(this, "expandedRow", 'expanded-row');
      /**
       * CSS-classes for table footer
       *
       * @property tfooterWrapper
       * @type string
       * @default 'table-footer'
       */
      _defineProperty(this, "tfooterWrapper", 'table-footer');
      /**
       * CSS-classes for wrapper inside `footer` component
       *
       * @property tfooterInternalWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "tfooterInternalWrapper", '');
      /**
       * CSS-classes for table summary block. Used in the `summary` component
       *
       * @property footerSummary
       * @type string
       * @default 'table-summary'
       */
      _defineProperty(this, "footerSummary", 'table-summary');
      /**
       * CSS-classes for table summary block. It's used when table has numeric pagination
       *
       * @property footerSummaryNumericPagination
       * @type string
       * @default ''
       */
      _defineProperty(this, "footerSummaryNumericPagination", '');
      /**
       * CSS-classes for table summary block. It's used when table has simple pagination
       *
       * @property footerSummaryDefaultPagination
       * @type string
       * @default ''
       */
      _defineProperty(this, "footerSummaryDefaultPagination", '');
      /**
       * CSS-classes for wrapper over "Page size"-block in the `footer` component
       *
       * @property pageSizeWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "pageSizeWrapper", '');
      /**
       * @property pageSizeSelectWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "pageSizeSelectWrapper", '');
      /**
       * Wrapper for select-tag in the current-page-number-select component
       *
       * @property currentPageSizeSelectWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "currentPageSizeSelectWrapper", '');
      /**
       * CSS-classes for `pagination-simple` and `pagination-numeric` components
       *
       * @property paginationWrapper
       * @type string
       * @default 'table-nav'
       */
      _defineProperty(this, "paginationWrapper", 'table-nav');
      /**
       * CSS-classes for buttons-wrapper in the `pagination-simple` and `pagination-numeric` components
       *
       * @property paginationInternalWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationInternalWrapper", '');
      /**
       * CSS-classes for `pagination-numeric` component
       *
       * @property paginationWrapperNumeric
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationWrapperNumeric", '');
      /**
       * CSS-classes for `pagination-simple` component
       *
       * @property paginationWrapperDefault
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationWrapperDefault", '');
      /**
       * @property paginationBlock
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationBlock", '');
      /**
       * CSS-classes for items in the `pagination-numeric` component
       *
       * @property paginationNumericItem
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationNumericItem", '');
      /**
       * CSS-classes for active item in the `pagination-numeric` component
       *
       * @property paginationNumericItemActive
       * @type string
       * @default ''
       */
      _defineProperty(this, "paginationNumericItemActive", '');
      /**
       * CSS-classes for "default" buttons
       *
       * @property buttonDefault
       * @type string
       * @default ''
       */
      _defineProperty(this, "buttonDefault", '');
      /**
       * CSS-classes for "link"-buttons
       *
       * @property buttonLink
       * @type string
       * @default ''
       */
      _defineProperty(this, "buttonLink", '');
      /**
       * CSS-classes for `td` shown when all columns are hidden
       *
       * @property noDataCell
       * @type string
       * @default ''
       */
      _defineProperty(this, "noDataCell", '');
      /**
       * @property collapseRow
       * @type string
       * @default 'collapseRow'
       */
      _defineProperty(this, "collapseRow", 'collapse-row');
      /**
       * @property collapseAllRows
       * @type string
       * @default 'collapse-all-rows'
       */
      _defineProperty(this, "collapseAllRows", 'collapse-all-rows');
      /**
       * @property expandRow
       * @type string
       * @default 'expand-row'
       */
      _defineProperty(this, "expandRow", 'expand-row');
      /**
       * @property expandAllRows
       * @type string
       * @default 'expand-all-rows'
       */
      _defineProperty(this, "expandAllRows", 'expand-all-rows');
      /**
       * @property cellContentDisplay
       * @type string
       * @default ''
       */
      _defineProperty(this, "cellContentDisplay", '');
      /**
       * @property cellContentEdit
       * @type string
       * @default ''
       */
      _defineProperty(this, "cellContentEdit", '');
      /**
       * CSS-classes for `thead`
       *
       * @property thead
       * @type string
       * @default ''
       */
      _defineProperty(this, "thead", '');
      /**
       * CSS-classes for `form`
       *
       * @property form
       * @type string
       * @default ''
       */
      _defineProperty(this, "form", '');
      /**
       * CSS-classes for wrapper over the form elements
       *
       * @property formElementWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "formElementWrapper", '');
      /**
       * CSS-classes for input elements
       *
       * @property input
       * @type string
       * @default ''
       */
      _defineProperty(this, "input", '');
      /**
       * CSS-classes for `select`
       *
       * @property select
       * @type string
       * @default ''
       */
      _defineProperty(this, "select", '');
      /**
       * CSS-classes for "Clear filter" button. Used for global filter and filters for each column
       *
       * @property clearFilterIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "clearFilterIcon", '');
      /**
       * CSS-classes for "Clear all filters" button inside the `summary` component
       *
       * @property clearAllFiltersIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "clearAllFiltersIcon", '');
      /**
       * @property globalFilterDropdownWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "globalFilterDropdownWrapper", '');
      /**
       * CSS-classes for `select` inside the `data-group-by-select` component
       *
       * @property changeGroupByField
       * @type string
       * @default 'change-group-by-field'
       */
      _defineProperty(this, "changeGroupByField", 'change-group-by-field');
      /**
       * CSS-classes for "sort asc/desc" button inside the `data-group-by-select` component
       *
       * @property sortGroupedPropertyBtn
       * @type string
       * @default ''
       */
      _defineProperty(this, "sortGroupedPropertyBtn", 'sort-grouped-field');
      /**
       * CSS-class for `row-grouping` component
       *
       * @property groupingRow
       * @type string
       * @default 'grouping-row'
       */
      _defineProperty(this, "groupingRow", 'grouping-row');
      /**
       * CSS-classes for `td` inside `row-grouping` component
       *
       * @property groupingCell
       * @type string
       * @default 'grouping-cell'
       */
      _defineProperty(this, "groupingCell", 'grouping-cell');
      /**
       * CSS-classes for icons used to show that some "list" is sorted "ASC". It's used for `data-group-by-select` and `row-sorting-cell`
       *
       * @property sortAscIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "sortAscIcon", '');
      /**
       * CSS-classes for icons used to show that some "list" is sorted "DESC". It's used for `data-group-by-select` and `row-sorting-cell`
       *
       * @property sortDescIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "sortDescIcon", '');
      /**
       * CSS-classes for icons in the `columns-dropdown` related to the visible columns
       *
       * @property columnVisibleIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnVisibleIcon", '');
      /**
       * CSS-classes for icons in the `columns-dropdown` related to the hidden columns
       *
       * @property columnHiddenIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "columnHiddenIcon", '');
      /**
       * CSS-classes for icon used in the "First"-button (`pagination-simple`)
       *
       * @property navFirstIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "navFirstIcon", '');
      /**
       * CSS-classes for icon used in the "Prev"-button (`pagination-simple`)
       *
       * @property navPrevIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "navPrevIcon", '');
      /**
       * CSS-classes for icon used in the "Next"-button (`pagination-simple`)
       *
       * @property navNextIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "navNextIcon", '');
      /**
       * CSS-classes for icon used in the "Last"-button (`pagination-simple`)
       *
       * @property navLastIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "navLastIcon", '');
      /**
       * CSS-classes for "caret"-icon used in the `columns-dropdown`
       *
       * @property caretIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "caretIcon", '');
      /**
       * @property selectAllRowsIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "selectAllRowsIcon", '');
      /**
       * @property deselectAllRowsIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "deselectAllRowsIcon", '');
      /**
       * @property selectSomeRowsIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "selectSomeRowsIcon", '');
      /**
       * @property selectRowIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "selectRowIcon", '');
      /**
       * @property deselectRowIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "deselectRowIcon", '');
      /**
       * @property editRowButton
       * @type string
       * @default ''
       */
      _defineProperty(this, "editRowButton", '');
      /**
       * @property saveRowButton
       * @type string
       * @default ''
       */
      _defineProperty(this, "saveRowButton", '');
      /**
       * @property cancelRowButton
       * @type string
       * @default ''
       */
      _defineProperty(this, "cancelRowButton", '');
      /**
       * @property filteringCellInternalWrapper
       * @type string
       * @default ''
       */
      _defineProperty(this, "filteringCellInternalWrapper", '');
      /**
       * @property expandRowIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "expandRowIcon", '');
      /**
       * @property collapseRowIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "collapseRowIcon", '');
      /**
       * @property collapseAllRowsIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "collapseAllRowsIcon", '');
      /**
       * @property expandAllRowsIcon
       * @type string
       * @default ''
       */
      _defineProperty(this, "expandAllRowsIcon", '');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "cellComponent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cellContentDisplayComponent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cellContentEditComponent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cellEditToggleComponent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cellContentSummaryComponent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "columnsDropdownComponent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "columnsHiddenComponent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dataGroupBySelectComponent", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "expandAllToggleComponent", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "expandToggleComponent", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "footerComponent", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "globalFilterComponent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "groupedHeaderComponent", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "noDataComponent", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "pageSizeSelectComponent", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "paginationNumericComponent", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "paginationSimpleComponent", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rowComponent", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "rowExpandComponent", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "rowFilteringComponent", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "rowFilteringCellComponent", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "rowGroupingComponent", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "rowGroupToggleComponent", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "rowSelectAllCheckboxComponent", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "rowSelectCheckboxComponent", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "rowSortingComponent", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "rowSortingCellComponent", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "selectComponent", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "summaryComponent", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "tableComponent", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "tableBodyComponent", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "tableFooterComponent", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "tableHeaderComponent", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});