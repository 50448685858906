define("ember-class-based-modifier/-private/modifier-manager", ["exports", "@ember/modifier", "@ember/object", "ember-class-based-modifier/-private/modifier-native"], function (_exports, _modifier, _object, _modifierNative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ClassBasedModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.13'));
    }
    createModifier(factory, args) {
      return factory.create({
        args
      });
    }
    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      (0, _object.set)(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      instance.willRemove();
      instance.element = null;
      if ((0, _modifierNative.isNative)(instance)) {
        (0, _modifierNative.destroy)(instance);
      } else {
        instance.destroy();
      }
    }
  }
  var _default = _exports.default = new ClassBasedModifierManager();
});