define("ember-models-table/components/models-table/page-size-select", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/computed", "ember-models-table/templates/components/models-table/page-size-select"], function (_exports, _component, _component2, _object, _computed, _pageSizeSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Dropdown with page size values used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Value from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions) is used as a list of dropdown-options.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect as |SizeSelectBlock|>
   *       <SizeSelectBlock.Select />
   *     </Footer.PageSizeSelect>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePageSizeSelect yields references to the following contextual components:
   *
   * * Select - selectbox with list of available page size options
   *
   * @class ModelsTablePageSizeSelect
   * @namespace Components
   * @extends Ember.Component
   */
  let PageSizeSelectComponent = _exports.default = (_dec = (0, _component.layout)(_pageSizeSelect.default), _dec2 = (0, _computed.alias)('themeInstance.pageSizeWrapper'), _dec3 = (0, _object.computed)('elementId'), _dec(_class = (_class2 = class PageSizeSelectComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property pageSizeWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "pageSizeWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions)
       *
       * @property pageSizeOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "pageSizeOptions", null);
      /**
       * Bound from [ModelsTable.pageSize](Components.ModelsTable.html#property_pageSize)
       *
       * @property pageSize
       * @type number
       * @default 10
       */
      _defineProperty(this, "pageSize", 10);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-page-size-select`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "pageSizeWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _class2)) || _class);
});