define("ember-class-based-modifier/-private/modifier-native", ["exports", "ember", "@ember/application", "@ember/modifier", "@ember/runloop", "ember-class-based-modifier/-private/modifier-manager"], function (_exports, _ember, _application, _modifier, _runloop, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.destroy = destroy;
  _exports.isNative = isNative;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const IS_NATIVE = Symbol('native');
  const DESTROYING = Symbol('destroying');
  const DESTROYED = Symbol('destroyed');
  class ClassBasedModifier {
    static create(options) {
      let owner = (0, _application.getOwner)(options);
      let {
        args
      } = options;
      return new this(owner, args);
    }
    constructor(owner, args) {
      _defineProperty(this, IS_NATIVE, true);
      _defineProperty(this, DESTROYING, false);
      _defineProperty(this, DESTROYED, false);
      (0, _application.setOwner)(this, owner);
      this.element = null;
      this.args = args;
    }
    didReceiveArguments() {}
    didUpdateArguments() {}
    didInstall() {}
    willRemove() {}
    willDestroy() {}
    get isDestroying() {
      return this[DESTROYING];
    }
    get isDestroyed() {
      return this[DESTROYED];
    }
  }
  _exports.default = ClassBasedModifier;
  (0, _modifier.setModifierManager)(() => _modifierManager.default, ClassBasedModifier);
  function isNative(modifier) {
    return modifier[IS_NATIVE] === true;
  }
  function destroy(modifier) {
    if (modifier[DESTROYING]) {
      return;
    }
    let meta = _ember.default.meta(modifier);
    meta.setSourceDestroying();
    modifier[DESTROYING] = true;
    (0, _runloop.schedule)('actions', modifier, modifier.willDestroy);
    (0, _runloop.schedule)('destroy', undefined, scheduleDestroy, modifier, meta);
  }
  function scheduleDestroy(modifier, meta) {
    if (modifier[DESTROYED]) {
      return;
    }
    _ember.default.destroy(modifier);
    meta.setSourceDestroyed();
    modifier[DESTROYED] = true;
  }
});