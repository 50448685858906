define("ember-google-maps/components/g-map/map-component", ["exports", "@ember/component", "ember-google-maps/utils/options-and-events", "@ember/object", "@ember/object/computed", "rsvp", "@ember/debug"], function (_exports, _component, _optionsAndEvents, _object, _computed, _rsvp, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentAPI = MapComponentAPI;
  _exports.MapComponentLifecycleEnum = void 0;
  _exports.combine = combine;
  _exports.default = void 0;
  function combine(base, extra) {
    return Object.defineProperties(base, Object.getOwnPropertyDescriptors(extra));
  }
  function MapComponentAPI(source) {
    return {
      get map() {
        return source.map;
      },
      get mapComponent() {
        return source.mapComponent;
      },
      get isInitialized() {
        return source.isInitialized;
      },
      actions: {
        update: () => source._updateComponent
      }
    };
  }
  const NOT_READY = 1,
    IN_PROGRESS = 2,
    READY = 3;
  const MapComponentLifecycleEnum = _exports.MapComponentLifecycleEnum = {
    NOT_READY,
    IN_PROGRESS,
    READY
  };

  /**
   * @class MapComponent
   * @module ember-google-maps/components/g-map/map-component
   * @namespace GMap
   * @extends Component
   */
  const MapComponent = _exports.default = _component.default.extend({
    tagName: '',
    _type: undefined,
    mapComponentLifecycle: NOT_READY,
    /* Options and events */

    _createOptions(options) {
      return options;
    },
    _createEvents(events) {
      return events;
    },
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)(_optionsAndEvents.ignoredOptions),
    _options: (0, _computed.readOnly)('_optionsAndEvents.options'),
    _events: (0, _computed.readOnly)('_optionsAndEvents.events'),
    /* Lifecycle hooks */

    init() {
      this._super(...arguments);
      (true && !(typeof this._type !== 'undefined') && (0, _debug.assert)('You must set a _type property on the map component.', typeof this._type !== 'undefined'));
      this._registrationType = this._pluralType || `${this._type}s`;
      this.isInitialized = (0, _rsvp.defer)();

      /**
       * An array of bound event listeners. Call `remove` on each before
       * destroying the component.
       */
      this._eventListeners = new Map();
      this.publicAPI = MapComponentAPI(this);
    },
    didInsertElement() {
      this._super(...arguments);
      this._internalAPI._registerComponent(this._registrationType, this.publicAPI);
      this._updateOrAddComponent();
    },
    didUpdateAttrs() {
      this._updateOrAddComponent();
    },
    willDestroyElement() {
      var _this$mapComponent, _this$mapComponent$se;
      this._super(...arguments);
      this._eventListeners.forEach(remove => remove());
      (_this$mapComponent = this.mapComponent) === null || _this$mapComponent === void 0 ? void 0 : (_this$mapComponent$se = _this$mapComponent.setMap) === null || _this$mapComponent$se === void 0 ? void 0 : _this$mapComponent$se.call(_this$mapComponent, null);
      this._internalAPI._unregisterComponent(this._registrationType, this.publicAPI);
    },
    _updateOrAddComponent() {
      let options, events;
      switch (this.mapComponentLifecycle) {
        case READY:
          options = this._createOptions((0, _object.get)(this, '_options'));
          events = this._createEvents((0, _object.get)(this, '_events'));
          this._updateComponent(this.mapComponent, options, events);
          break;
        case IN_PROGRESS:
          break;
        // PASS

        case NOT_READY:
          if (typeof this.map === 'undefined') {
            break;
          }
          this.mapComponentLifecycle = IN_PROGRESS;
          options = this._createOptions((0, _object.get)(this, '_options'));
          events = this._createEvents((0, _object.get)(this, '_events'));
          (0, _rsvp.resolve)().then(() => this._addComponent(options, events)).then(mapComponent => this._didAddComponent(mapComponent, options, events)).then(() => {
            this.isInitialized.resolve();
            this.mapComponentLifecycle = READY;
          }).catch(() => {
            this.mapComponentLifecycle = NOT_READY;
          });
          break;
      }
    },
    /* Map component hooks */

    /**
     * Run when the map component is first initialized. Normally this happens as
     * soon as the map is ready.
     *
     * @method _addComponent
     * @return
     */
    _addComponent(/* options, events */
    ) {
      (true && !(false) && (0, _debug.assert)('Map components must implement the _addComponent hook.'));
      return (0, _rsvp.reject)();
    },
    /**
     * Run after the map component has been initialized. This hook should be used
     * to register events, etc.
     *
     * @method _didAddComponent
     * @return
     */
    _didAddComponent(mapComponent, options, events) {
      let payload = {
        map: this.map,
        publicAPI: this.publicAPI
      };
      (0, _optionsAndEvents.addEventListeners)(mapComponent, events, payload).forEach(({
        name,
        remove
      }) => this._eventListeners.set(name, remove));
      return (0, _rsvp.resolve)();
    },
    /**
     * Run when any of the attributes or watched options change.
     *
     * @method _updateComponent
     * @return
     */
    _updateComponent(mapComponent, options /* , events */) {
      mapComponent.setOptions(options);
    }
  });
});