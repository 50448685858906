define("ember-models-table/components/models-table/footer", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object/computed", "ember-models-table/templates/components/models-table/footer"], function (_exports, _component, _component2, _computed, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Footer block used within [models-table](Components.ModelsTable.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage example with a block context:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     <Footer.PageSizeSelect />
   *     {{#if useNumericPagination}}
   *       <Footer.PaginationNumeric />
   *     {{else}}
   *       <Footer.PaginationSimple />
   *     {{/if}}
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   *
   * ModelsTableFooter yields references to the following contextual components:
   *
   * * [models-table/summary](Components.ModelsTableSummary.html) - component with summary info about table data. It also contains button to clear all filters applied to the table
   * * [models-table/size-select](Components.ModelsTableSizeSelect.html) - component with a page sizes dropdown. It allows to select number if records shown on page
   * * [models-table/pagination-numeric](Components.ModelsTablePaginationNumeric.html) - component with a table navigation. It allows to move to the page by its number
   * * [models-table/pagination-simple](Components.ModelsTablePaginationSimple.html) - component with a table navigation. It allows to move to the first, last, prev and next pages (this four buttons are shown always)
   *
   * Check own docs for each component to get detailed info.
   *
   * @class ModelsTableFooter
   * @namespace Components
   * @extends Ember.Component
   */
  let FooterComponent = _exports.default = (_dec = (0, _component.layout)(_footer.default), _dec2 = (0, _computed.alias)('themeInstance.tfooterInternalWrapper'), _dec(_class = (_class2 = class FooterComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property tfooterInternalWrapper
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "tfooterInternalWrapper", _descriptor, this);
      /**
       * Bound from [ModelsTable.collapseNumPaginationForPagesCount](Components.ModelsTable.html#property_collapseNumPaginationForPagesCount)
       *
       * @property collapseNumPaginationForPagesCount
       * @type number
       * @default null
       */
      _defineProperty(this, "collapseNumPaginationForPagesCount", null);
      /**
       * Bound from [ModelsTable.firstIndex](Components.ModelsTable.html#property_firstIndex)
       *
       * @property firstIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "firstIndex", null);
      /**
       * Bound from [ModelsTable.lastIndex](Components.ModelsTable.html#property_lastIndex)
       *
       * @property lastIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "lastIndex", null);
      /**
       * @property recordsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "recordsCount", null);
      /**
       * Bound from [ModelsTable.anyFilterUsed](Components.ModelsTable.html#property_anyFilterUsed)
       *
       * @default null
       * @property anyFilterUsed
       * @type boolean
       */
      _defineProperty(this, "anyFilterUsed", null);
      /**
       * Bound from [ModelsTable.currentPageNumberOptions](Components.ModelsTable.html#property_currentPageNumberOptions)
       *
       * @property currentPageNumberOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "currentPageNumberOptions", null);
      /**
       * Bound from [ModelsTable.pageSizeOptions](Components.ModelsTable.html#property_pageSizeOptions)
       *
       * @property pageSizeOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "pageSizeOptions", null);
      /**
       * Bound from [ModelsTable.pageSize](Components.ModelsTable.html#property_pageSize)
       *
       * @property pageSize
       * @type number
       * @default 10
       */
      _defineProperty(this, "pageSize", 10);
      /**
       * Bound from [ModelsTable.currentPageNumber](Components.ModelsTable.html#property_currentPageNumber)
       *
       * @property currentPageNumber
       * @type number
       * @default 1
       */
      _defineProperty(this, "currentPageNumber", 1);
      /**
       * Bound from [ModelsTable.showCurrentPageNumberSelect](Components.ModelsTable.html#property_showCurrentPageNumberSelect)
       *
       * @property showCurrentPageNumberSelect
       * @default null
       * @type boolean
       */
      _defineProperty(this, "showCurrentPageNumberSelect", null);
      /**
       * Bound from [ModelsTable.pagesCount](Components.ModelsTable.html#property_pagesCount)
       *
       * @property pagesCount
       * @type number
       * @default null
       */
      _defineProperty(this, "pagesCount", null);
      /**
       * Bound from [ModelsTable.showPageSize](Components.ModelsTable.html#property_showPageSize)
       *
       * @property showPageSize
       * @type boolean
       * @default null
       */
      _defineProperty(this, "showPageSize", null);
      /**
       * Bound from [ModelsTable.useNumericPagination](Components.ModelsTable.html#property_useNumericPagination)
       *
       * @property useNumericPagination
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useNumericPagination", null);
      /**
       * Closure action [ModelsTable.goToPage](Components.ModelsTable.html#event_goToPage)
       *
       * @event goToPage
       */
      _defineProperty(this, "goToPage", null);
      /**
       * Closure action [ModelsTable.clearFilters](Components.ModelsTable.html#event_clearFilters)
       *
       * @event clearFilters
       */
      _defineProperty(this, "clearFilters", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "tfooterInternalWrapper", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
});